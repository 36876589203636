import JsBridge from "./JsBridge";
// import db from './localstorage';
import * as config from "../config/config";
import testResquest from "./testRequest";
import store from "@/store";
// import router from "@/router";
// import db from "./localstorage";

export default {
  //初始化http api基址
  initHttpRequestBaseUrl() {
    let requestUrl;

    if (process.env.NODE_ENV == "development") {
      requestUrl = config.requestBaseUrl;
    } else {
      requestUrl = `${window.location.protocol}//${window.location.host}/`;
    }

    JsBridge.callHandler("initHttpRequestBaseUrl", requestUrl);
  },

  //同步用户信息到Native端
  logined(params) {
    JsBridge.callHandler("logined", params);
  },

  //修改密码操作处理后，需同步用户信息给Native端
  changeUserInfo(params) {
    JsBridge.callHandler("changeUserInfo", params);
  },

  //注销登录
  logout() {
    JsBridge.callHandler("logout");
  },

  //启动加载中
  showLoading() {
    JsBridge.callHandler("showLoading");
  },

  //关闭加载中
  closeLoading() {
    JsBridge.callHandler("closeLoading");
  },

  //返回版本名
  appVerName(callback) {
    JsBridge.callHandler("appVerName", null, res => {
      callback(res);
    });
  },

  //返回app版本比对码
  appVerCode(callback, failFunc) {
    JsBridge.callHandler(
      "appVerCode",
      null,
      res => {
        callback(res);
      },
      failFunc
    );
  },

  //返回上一页
  goBack() {
    JsBridge.callHandler("goBack");
  },

  //改变状态栏图标的样式
  changeStatusBarStyle(color) {
    JsBridge.callHandler("changeStatusBarStyle", color);
  },

  updateApp(url) {
    JsBridge.callHandler("updateApp", url);
  },

  //发起视屏通话
  callVideoTalk() {
    testResquest.test("video").then(res => {
      let params = res.data;
      console.log(params);
      JsBridge.callHandler("callVideoTalk", params);
    });
    // let token = db.get('VIDEO_TOKEN' , '') ? db.get('VIDEO_TOKEN' , '') : '00624061173d61246599a1ce4a57015f84eIACFqi3j+WGDtA90TorhLngtQx5Mtk2PPr0GaQlP/G27bDSylNQAAAAAEAAWal0mEPbOXwEAAQAP9s5f'
  },

  //监听日志
  registerShowLog() {
    JsBridge.registerHandler("showLog", () => {
      // console.log(res) ;
    });
  },

  //获取Native标签栏高度
  getNavigationBarStatus() {
    JsBridge.callHandler("getNavigationBarStatus", null, res => {
      let date = JSON.parse(res);
      console.log("getNavigationBarStatus ==> ", res);
      if (date["showing"]) {
        let radio = document.body.clientHeight / date["screenHeight"];

        console.log(
          "比率 ==> ",
          radio,
          document.body.clientHeight,
          date["screenHeight"]
        );
        store.commit("changeNativeBarHeight", date["height"] * radio);
      } else {
        store.commit("changeNativeBarHeight", 0);
      }
    });
  },

  //监听Native标签栏高度改变
  onChangeNavigationBarStatus(callback) {
    JsBridge.registerHandler("onChangeNavigationBarStatus", () => {
      callback();
      // let data = JSON.parse(res);
      // console.log("onChangeNavigationBarStatus ==>", JSON.parse(res));
      // if (data["showing"]) {
      //   let radio = document.body.clientHeight / data["screenHeight"];

      //   console.log(
      //     "比率 ==> ",
      //     radio,
      //     document.body.clientHeight,
      //     data["screenHeight"]
      //   );
      //   store.commit("changeNativeBarHeight", data["height"] * radio);
      // } else {
      //   store.commit("changeNativeBarHeight", 0);
      // }
    });
  },

  //监听未读消息更新
  updateMsgCount(callback) {
    JsBridge.registerHandler("updateMsgCount", () => {
      callback();
    });
  },

  syncNotifyMode(mode) {
    // console.log(mode);
    JsBridge.callHandler("syncNotifyMode", mode);
  },

  showMessage(callback) {
    JsBridge.registerHandler("showMessage", () => {
      callback();
    });
  }
};
