/**
 * 函数描述：js调用webview事件
 *
 * jsBridge.callHandler(method, data, callBack(response));
 * @param method {string} 方法名
 * @param data {Object} 参数
 * @return {Object} 回调
 */

/**
 * 函数描述：webView调用JS事件
 *
 * jsBridge.registerHandler(method, callBack(response));
 * @param method {string} 方法名
 * @return {Object} 回调
 */



let JsBridge = {
    init: function (callback) {
        let u = navigator.userAgent;
        let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1; //android终端
        // let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); //iOS终端
        if (isAndroid) {
            if (window.WebViewJavascriptBridge) {
                callback(window.WebViewJavascriptBridge)
            } else {
                document.addEventListener(
                    'WebViewJavascriptBridgeReady',
                    function () {
                        callback(window.WebViewJavascriptBridge)
                    },
                    false
                );
            }
        } 
        // else if (isiOS) {
        //     if (window.WebViewJavascriptBridge) {
        //         return callback(window.WebViewJavascriptBridge);
        //     }
        //     if (window.WVJBCallbacks) {
        //         return window.WVJBCallbacks.push(callback);
        //     }
        //     window.WVJBCallbacks = [callback];
        //     var WVJBIframe = document.createElement('iframe');
        //     WVJBIframe.style.display = 'none';
        //     WVJBIframe.src = 'wvjbscheme://__BRIDGE_LOADED__';
        //     document.documentElement.appendChild(WVJBIframe);
        //     setTimeout(function () {
        //         document.documentElement.removeChild(WVJBIframe)
        //     }, 0)
        //     return Promise.resolve('success')
        // }
    },

    first: function () {
        var u = navigator.userAgent;
        var isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
        if (!isiOS) {
            var _this = this;
            _this.init(function (bridge) {
                bridge.init(function (message, responseCallback) {
                    responseCallback();
                })
            })
        }
    },

    registerHandler: function (name, fun) {
        var _this = this;
        _this.init(function (bridge) {
            bridge.registerHandler(name, fun);
        })
    },

    callHandler: function (name, data, fun ) {
        var _this = this;
        
        _this.init(function (bridge) {
            bridge.callHandler(name, data, fun);
        })
    },
}

JsBridge.first();


export default JsBridge