import Vue from "vue";
import Vuex from "vuex";
import account from "./module/account";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    isLoading: false,
    isApp: false,
    nativeBarHeight: 0
  },
  mutations: {
    showLoading(state) {
      state.isLoading = true;
    },
    closeLoading(state) {
      state.isLoading = false;
    },
    setIsApp(state, val) {
      state.isApp = val;
    },
    changeNativeBarHeight(state, val) {
      console.log("val ==> ", val);
      if (!val) {
        console.log(
          "这不是app ==> ",
          document.body.clientHeight,
          window.innerHeight
        );
      }
      state.nativeBarHeight = val;
    }
  },
  actions: {},
  modules: {
    account
  }
});
