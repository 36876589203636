// import store from '@/store';
let NativeUtil = {

    /**
     * 判断当前访问设备是否苹果
     */
    isIOS: () => {
        let u = window.navigator.userAgent
        if (u.indexOf('iPhone') > -1) {
            return true
        } else {
            return false
        }
    },

    /**
     * 是否桌面电脑（非手持终端）
     */
    isDesktop: () => {
        return NativeUtil.isMac() || NativeUtil.isWindow();
    },

    /**
     * 是否mac系统
     */
    isMac: () => {
        if (NativeUtil.isIOS()) return false;
        return /macintosh|mac os x/i.test(window.navigator.userAgent);
    },

    /**
     * 是否window系统
     */
    isWindow: () => {
        let agent = navigator.userAgent.toLowerCase();
        if (agent.indexOf("win32") >= 0 || agent.indexOf("wow32") >= 0) {
            return true;
        }
        if (agent.indexOf("win64") >= 0 || agent.indexOf("wow64") >= 0) {
            return true;
        }
        return false;
    },

    /**
     * 判断当前访问设备是否安卓
     */
    isAndroid: () => {
        let u = window.navigator.userAgent
        if (u.indexOf('Android') > -1) {
            return true
        } else {
            return false
        }
    },

    /**
     * 将map转换成对象
     * @param {Map} strMap 
     */
    convertMapToObj(strMap) {
        let obj = Object.create(null);
        for (let [k, v] of strMap) {
            obj[k] = v;
        }
        return obj;
    },

    /**
     * 将对象转换为Map
     * @param {Object} obj 
     */
    convertObjToMap(obj) {
        let strMap = new Map();
        for (let k of Object.keys(obj)) {
            strMap.set(k, obj[k]);
        }
        return strMap;
    },

    /**
     * 生成UUID
     */
    uuid() {
        let s = [];
        let hexDigits = "0123456789abcdef";
        for (let i = 0; i < 36; i++) {
            s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
        }
        s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
        s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
        s[8] = s[13] = s[18] = s[23] = "-";
        let uuid = s.join("");
        return uuid;
    }
};

export default NativeUtil;