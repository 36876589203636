<template>
  <div id="app" :style="{ height: `${innerHeight - nativeBarHeight}px` }">
    <div id="loading" v-show="isLoading">
      <van-loading color="#fff">加载中...</van-loading>
    </div>
    <router-view v-if="RouterState" />
  </div>
</template>

<script>
import { mapState } from "vuex";
// eslint-disable-next-line
// import vConsole from "./utils/vconsole";
export default {
  data: () => ({
    viewHeight: 0,
    innerHeight: 0,
    RouterState: true
  }),

  provide() {
    return {
      reload: this.reload
    };
  },

  computed: {
    // innerHeight() {
    //   // console.log("屏幕高度", window.innerHeight);
    //   // return window.innerHeight;
    //   return window.innerHeight;
    // },
    ...mapState({
      isLoading: state => state.isLoading,
      nativeBarHeight: state => state.nativeBarHeight
    })
  },
  methods: {
    refreshHeight() {
      clearTimeout(this.timer);
      this.timer = setTimeout(() => {
        this.innerHeight = window.innerHeight;
      }, 500);
    },

    reload() {
      this.RouterState = false;
      this.$nextTick(() => {
        this.RouterState = true;
      });
    }
  },
  created() {
    document.title = "智即停综合运营";
  },

  mounted() {
    this.$jsBridge.registerShowLog();
    this.$jsBridge.getNavigationBarStatus();

    let msgMode = this.$db.get("MSG_MODE", "") || this.$config.msgMode;
    console.log(msgMode);
    this.$jsBridge.syncNotifyMode(JSON.parse(msgMode));
    this.$jsBridge.showMessage(() => {
      let token = this.$db.get("USER_TOKEN", "");
      let user = this.$db.get("USER_NAME", "");
      if (token.length && user) {
        // 有token 且 有用户信息，则进行获取路由信息
        if (this.$route.name == "Message") {
          this.reload();
        } else {
          this.$router.push({
            name: "Message"
          });
        }
      }
    });

    //改变窗口高度
    this.refreshHeight();
    window.addEventListener("resize", () => {
      this.innerHeight = window.innerHeight;
    });
  },

  beforeDestroy() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }
};
</script>

<style lang="less">
html,
body,
ul,
ol,
li,
h1,
h2,
h3,
h4,
h5,
h6,
p,
table,
tr,
th,
td,
div,
input {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: "微软雅黑";
}

a {
  text-decoration: none;
}

.auto_img {
  display: block;
  width: 100%;
  height: auto;
}

#app {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  position: relative;
}

.titleBar {
  height: 24px;
  width: 100%;
  background-color: #06338e;
  position: relative;
  z-index: 55;

  &::before {
    content: "";
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10000;
    width: 100vw;
    height: 24px;
    background-color: #06338e;
  }
}

.pageTitle {
  width: 100%;
  height: 48px;
  background-color: #06338e;

  display: flex;
  align-items: center;
  color: #fff;
  font-size: 20px;
  position: relative;
  z-index: 55;

  .title_icon {
    margin: 0 18px;
    cursor: pointer;
  }

  .title_txt {
    font-size: 16px;
    flex: 1;
    margin-top: -6px;
  }

  .title_select {
    font-size: 16px;
    padding: 0 12px;
  }
}

#loading {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 50;
  display: flex;
  justify-content: center;
  align-items: center;

  .van-loading {
    .van-loading__spinner {
      width: 40px;
      height: 40px;
    }
    .van-loading__text {
      color: #fff;
      font-size: 16px;
    }
  }
}

.van-pull-refresh__track {
  min-height: 50vh;
}

.clearFix::after {
  content: "";
  display: block;
  clear: both;
}
</style>
