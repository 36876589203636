import axios from "axios";
import { Dialog } from "vant";
import moment from "moment";
import store from "@/store";
import db from "@/utils/localstorage";
import $tools from "./tools";

moment.locale("zh-cn");

// 统一配置
let FEBS_REQUEST = axios.create({
  baseURL: "/api/",
  // baseURL: "http://172.18.10.75:9021",
  // baseURL: "http://172.18.1.68:8004/api/",
  responseType: "json",
  validateStatus(status) {
    // 200 外的状态码都认定为失败
    return status === 200;
  }
});
// 拦截请求
FEBS_REQUEST.interceptors.request.use(
  async config => {
    let expireTime = store.state.account.expireTime;
    let localExpireTime = db.get("EXPIRE_TIME", "");
    let now = moment().format("YYYYMMDDHHmmss");
    // 让token早10秒种过期，提升“请重新登录”弹窗体验
    if (now - expireTime >= -10 && localExpireTime) {
      // Modal.error({
      //   title: '登录已过期',
      //   content: '很抱歉，登录已过期，请重新登录',
      //   okText: '重新登录',
      //   mask: false,
      //   onOk: () => {
      //     return new Promise((resolve, reject) => {
      //       db.clear()
      //       location.reload()
      //     })
      //   }
      // })
      let token = db.get("USER_TOKEN", "");
      store.commit("account/setExpireTime", "");
      store.commit("account/setToken", "");

      //刷新token
      let obj = await request.get("/tl-login/updateToken", {
        Authentication: token
      });

      store.commit("account/setExpireTime", obj["data"]["data"]["expireTime"]);
      store.commit("account/setToken", obj["data"]["data"]["token"]);
    }

    if (store.state.account.token) {
      config.headers.Authentication = store.state.account.token;
    }

    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

// 拦截响应
FEBS_REQUEST.interceptors.response.use(
  config => {
    return config;
  },
  error => {
    if (error.response) {
      $tools.closeLoading();
      let errorMessage =
        error.response.data === null
          ? "系统内部异常，请联系网站管理员"
          : error.response.data.message;
      switch (error.response.status) {
        case 404:
          Dialog.alert({
            title: "系统提示",
            message: "很抱歉，资源未找到"
          });
          break;
        case 403:
          Dialog.alert({
            title: "系统提示",
            message: "很抱歉，您无法访问该资源，可能是因为没有相应权限"
          });
          break;
        case 401:
          Dialog.alert({
            title: "登录已过期",
            message: "很抱歉，登录已过期，请重新登录",
            confirmButtonText: "重新登录"
          }).then(() => {
            let c = db.get("_c", "");
            db.clear();
            if (c) {
              db.save("_c", c);
            }
            location.reload();
          });
          break;
        //   setTimeout(() => {
        //     db.clear()
        //     location.reload()
        //   }, 3000)
        //   break
        default:
          Dialog.alert({
            title: "系统提示",
            message: errorMessage
          });
          break;
      }
    }
    return Promise.reject(error);
  }
);

const request = {
  post(url, params) {
    return FEBS_REQUEST.post(url, params, {
      transformRequest: [
        params => {
          let result = "";
          Object.keys(params).forEach(key => {
            if (
              !Object.is(params[key], undefined) &&
              !Object.is(params[key], null)
            ) {
              result +=
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(params[key]) +
                "&";
            }
          });
          return result;
        }
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  },
  put(url, params) {
    return FEBS_REQUEST.put(url, params, {
      transformRequest: [
        params => {
          let result = "";
          Object.keys(params).forEach(key => {
            if (
              !Object.is(params[key], undefined) &&
              !Object.is(params[key], null)
            ) {
              result +=
                encodeURIComponent(key) +
                "=" +
                encodeURIComponent(params[key]) +
                "&";
            }
          });
          return result;
        }
      ],
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });
  },
  get(url, params) {
    let _params;
    if (Object.is(params, undefined)) {
      _params = "";
    } else {
      _params = "?";
      for (let key in params) {
        if (params[key] && params[key] !== null) {
          _params += `${key}=${params[key]}&`;
        }
      }
    }
    return FEBS_REQUEST.get(`${url}${_params}`);
  },
  delete(url, params) {
    let _params;
    if (Object.is(params, undefined)) {
      _params = "";
    } else {
      _params = "?";
      for (let key in params) {
        if (params[key] && params[key] !== null) {
          _params += `${key}=${params[key]}&`;
        }
      }
    }
    return FEBS_REQUEST.delete(`${url}${_params}`);
  }
};

export default request;
