import moment from "moment";

const MMM_Do_filter = (date, mode) => {
  if (mode == "ymd") {
    return `${moment(date).format("YYYY")}年`;
  } else if (mode == "md") {
    return moment(date).format("MMMDo");
  } else {
    return moment(date).format("YYYY年MMM");
  }
};

const typeFilter = type => {
  switch (type) {
    case 1:
      return "收费员";
    case "1":
      return "收费员";
    case 3:
      return "队长";
    case "3":
      return "队长";
    default:
      return "未知";
  }
};

/**
 * 处理获取 hh:mm:ss 时间格式
 * @param {Date} date
 */
const dateFilter_hh_mm_ss = date => {
  if (date) {
    let time = new Date(date);
    let h = time.getHours() < 10 ? "0" + time.getHours() : time.getHours();
    let m =
      time.getMinutes() < 10 ? "0" + time.getMinutes() : time.getMinutes();
    let s =
      time.getSeconds() < 10 ? "0" + time.getSeconds() : time.getSeconds();

    return `${h}:${m}:${s}`;
  } else {
    return "";
  }
};

/**
 * 处理获取 YYYY年MM月DD日 时间格式
 * @param {Date} date
 * @param {Number} type 选择日期输出类型
 */
const dateFilter_YY_MM_DD = (date, type = 1) => {
  if (date) {
    let time = new Date(date);
    let y = time.getFullYear();
    let m =
      time.getMonth() + 1 < 10
        ? "0" + (time.getMonth() + 1)
        : time.getMonth() + 1;
    let d = time.getDate() < 10 ? "0" + time.getDate() : time.getDate();
    if (type === 1) {
      return `${y}年${m}月${d}日`;
    } else {
      return `${y}-${m}-${d}`;
    }
  } else {
    return "";
  }
};

const dateFilter_YY_MM = (date, type = 1) => {
  if (date) {
    let time = new Date(date);
    let y = time.getFullYear();
    let m =
      time.getMonth() + 1 < 10
        ? "0" + (time.getMonth() + 1)
        : time.getMonth() + 1;
    if (type === 1) {
      return `${y}年${m}月`;
    } else {
      return `${y}-${m}`;
    }
  } else {
    return "";
  }
};

/**
 * 处理获取 yyyy-MM-dd hh-mm-ss 时间格式
 * @param {Date} date
 */
const dateFilter_yy_MM_dd_hh_mm_ss = date => {
  if (date) {
    return dateFilter_YY_MM_DD(date, 2) + " " + dateFilter_hh_mm_ss(date);
  } else {
    return "";
  }
};

export default {
  MMM_Do_filter,
  typeFilter,
  dateFilter_hh_mm_ss,
  dateFilter_YY_MM_DD,
  dateFilter_yy_MM_dd_hh_mm_ss,
  dateFilter_YY_MM
};
