import db from "@/utils/localstorage";

export default {
  namespaced: true,
  state: {
    token: db.get("USER_TOKEN", ""),
    expireTime: db.get("EXPIRE_TIME", ""),
    account: db.get("ACCOUNT", ""),
    avatar: db.get("AVATAR", ""),
    userId: db.get("USER_ID", ""),
    phone: db.get("PHONE", ""),
    username: db.get("USER_NAME", ""),
    city: db.get("CITY", ""),
    type: db.get("TYPE", "")
  },
  mutations: {
    setType(state, val) {
      db.save("TYPE", val);
      state.type = val;
    },
    setToken(state, val) {
      db.save("USER_TOKEN", val);
      state.token = val;
    },
    setExpireTime(state, val) {
      db.save("EXPIRE_TIME", val);
      state.expireTime = val;
    },
    setAccount(state, val) {
      db.save("ACCOUNT", val);
      state.account = val;
    },
    setAvatar(state, val) {
      db.save("AVATAR", val);
      state.avatar = val;
    },
    setUserId(state, val) {
      db.save("USER_ID", val);
      state.userId = val;
    },
    setPhone(state, val) {
      db.save("PHONE", val);
      state.phone = val;
    },
    setUserName(state, val) {
      db.save("USER_NAME", val);
      state.username = val;
    },
    setCity(state, val) {
      db.save("CITY", val);
      state.city = val;
    }
  }
};
