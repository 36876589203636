import axios from 'axios'

// json文件映射 实际请求相对路径 匹配 本地json文件
const jsonMapping = new Map([
  ['/video', 'video.json']
])

// 请求本地json数据
let TEST_REQUEST = axios.create({
  baseURL: './static',
  responseType: 'json',
  validateStatus (status) {
    // 200 外的状态码都认定为失败
    return status === 200
  }
})

// 拦截请求
TEST_REQUEST.interceptors.request.use(resqust => {
  console.log(`请求拦截 = ${resqust.baseURL}${resqust.url}`)
  let flag
  for (let item of jsonMapping) {
    if (item[0] === resqust.url) {
      resqust.url = item[1]
      flag = true
      break
    }
  }
  if (!flag) {
    let arr = resqust.url.split('.')
    if ((arr.length > 1 && arr[arr.length - 1] !== 'json') || arr.length === 1) {
      resqust.url = resqust.url + '.json'
    }
  }
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve(resqust)
    }, 1000)
  })
}, err => {
  return Promise.reject(err)
})

const testRequest = {
  // 测试本地数据用，测试的json文件可放在static/test文件夹
  test (url) {
    return TEST_REQUEST.get(url)
  }
}

export default testRequest
