import Vue from "vue";
import VueRouter from "vue-router";
import db from "@/utils/localstorage.js";
import routes from "./routes";
Vue.use(VueRouter);

const router = new VueRouter({
  routes
});

const clearList = ["/login"];

// 路由白名单
const whiteList = [];

// 导航守卫，渲染动态路由
router.beforeEach((to, from, next) => {
  if (clearList.indexOf(to.path) !== -1) {
    //如果储存了账号密码，进行获取
    let a = db.get("_a", "");
    let b = db.get("_b", "");
    let c = db.get("_c", "");
    let msgMode = db.get("MSG_MODE", "");

    db.clear();

    if (a && b) {
      db.save("_a", a);
      db.save("_b", b);
    }

    if (c) {
      db.save("_c", c);
    }

    if (msgMode) {
      db.save("MSG_MODE", msgMode);
    }

    next();
  } else if (whiteList.indexOf(to.path) !== -1) {
    next();
  } else {
    let token = db.get("USER_TOKEN", "");
    let user = db.get("USER_NAME", "");
    if (token.length && user) {
      // 有token 且 有用户信息，则进行获取路由信息
      next();
    } else {
      // 没有token没有用户信息直接跳转去login页面
      next("/login");
      // next()
    }
  }
});

export default router;
