const routes = [
  {
    path: "/login",
    name: "Login",
    component: () => import("../views/Login.vue")
  },
  {
    path: "/index",
    name: "Index",
    component: () => import("../views/Index.vue")
  },
  {
    path: "/charge",
    name: "Charge",
    component: () => import("../views/Charge.vue"),
    children: [
      {
        path: "report",
        name: "Report",
        component: () => import("../views/Charge/Report.vue")
      }
    ]
  },
  {
    path: "/insideCharge",
    name: "InsideCharge",
    component: () => import("../views/InsideCharge.vue"),
    children: [
      {
        path: "insideReport",
        name: "InsideReport",
        component: () => import("../views/InsideCharge/insideChargeReport.vue")
      }
    ]
  },
  {
    path: "/attention",
    name: "Attention",
    component: () => import("../views/Charge/Attention.vue"),
    children: [
      {
        path: "attentionOrder",
        name: "AttentionOrder",
        component: () => import("../views/Charge/AttentionOrder.vue")
      }
    ]
  },
  {
    path: "/team",
    name: "Team",
    component: () => import("../views/Team.vue"),
    children: [
      {
        path: "/selfAttendance",
        name: "SelfAttendance",
        component: () => import("../views/Team/SelfAttendance.vue")
      },
      {
        path: "/attendance",
        name: "Attendance",
        component: () => import("../views/Team/Attendance.vue")
      }
    ]
  },
  {
    path: "/scheduling",
    name: "Scheduling",
    component: () => import("../views/Scheduling.vue")
  },
  {
    path: "/editPassword",
    name: "EditPassword",
    component: () => import("../views/EditPassword.vue")
  },

  {
    path: "/setting",
    name: "Setting",
    component: () => import("../views/Setting.vue")
  },
  {
    path: "/message",
    name: "Message",
    component: () => import("../views/Message.vue")
  },
  {
    path: "/plateNumber",
    name: "PlateNumber",
    component: () => import("../views/PlateNumber.vue")
  },
  {
    path: "/orderDetail",
    name: "OrderDetail",
    component: () => import("../views/OrderDetail.vue")
  },

  {
    path: "/coupons",
    name: "Coupons",
    component: () => import("../views/Coupons.vue"),
    children: [
      {
        path: "/couponsRecord/:id",
        name: "CouponsRecord",
        component: () => import("../views/Coupons/CouponsRecord.vue")
      }
    ]
  },

  {
    path: "*",
    redirect: "/login"
  }
];

export default routes;
