import request from "../utils/request";

let api = {
  /**
   * 登录
   * @param {String} tenantName 租户名
   * @param {String} userName 用户名
   * @param {String} password 密码
   */
  login({ tenantName, userName, password }) {
    return request.post("/tl-login/login", {
      tenantName,
      userName,
      password
    });
  },

  /**
   * 获取今日收费数据
   * @param {Object} params
   *    dateType {Integer} 检索日期类型
   *    stationId {String}  岗位Id
   */
  getDayCharge(params) {
    return request.get("/tl-charge-status/getDayCharge", params);
  },

  /**
   * 获取总报表（详细报表）
   * @param {Date} timeFrom 开始日期
   * @param {Date} timeTo 结束日期
   * @param {Integer} dateType  检索日期类型
   * @param {Integer} type  检索数据类型
   */
  getStationOrderFlow({ timeFrom, timeTo, dateType, type }) {
    return request.get("/tl-charge-status/getStationOrderFlow", {
      timeFrom,
      timeTo,
      dateType,
      type
    });
  },

  /**
   * 获取收费趋势（收费岗-收费趋势同）
   * @param {Object} params
   *    dateType {Integer} 检索日期类型
   *    stationId {String}  岗位Id
   */
  getIncome(params) {
    return request.get("/tl-charge-status/getIncome", params);
  },

  /**
   * 获取收费岗
   */
  getStationByCaptain() {
    return request.get("/tl-charge-status/getStationByCaptain");
  },

  /**
   * 获取收费排行榜
   * @param {Integer} dateType  检索日期类型
   * @param {Integer} type  检索时间范围
   */
  getRankingList({ dateType, type }) {
    return request.get("/tl-charge-status/getRankingList", { dateType, type });
  },

  /**
   * 修改密码
   * @param {String} lastPassword   旧密码
   * @param {String} newPassword    新密码
   */
  updatePassword({ lastPassword, newPassword }) {
    return request.post("/employee/tlUpdatePassword", {
      lastPassword,
      newPassword
    });
  },

  /**
   * 获取今日关注数据
   */
  getTodayFocus() {
    return request.get("/tl-charge-status/getTodayFocus");
  },

  /**
   * 获取今日关注车辆列表
   * @param {Object} params
   *    type {Integer}  车辆类型
   *    startTime {date}  开始时间
   *    endTime {date}  结束时间
   */
  getTodayFocusList(params) {
    return request.get("/tl-charge-status/getTodayFocusList", params);
  },

  //------------------班组信息模块----------------
  /**
   * 分页获取收费岗班组信息列表
   * @param {Integer} pageNum 页数
   * @param {Integer} pageSize 条数
   */
  getTeamInfoList({ pageNum, pageSize }) {
    return request.get("/tl-team-info/getTeamInfoList", {
      pageNum,
      pageSize
    });
  },

  /**
   * 获取收费岗考勤信息数据
   * @param {Long} stationId 岗位id
   * @param {Date} time 指定年月
   */
  getAttendanceInfo({ stationId, time }) {
    return request.get("/tl-team-info/getAttendanceInfo", { stationId, time });
  },

  /**
   * 分页获取收费员班组信息列表
   * @param {Integer} pageNum 页数
   * @param {Integer} pageSize 条数
   */
  getTollmanTeamInfoList({ pageNum, pageSize }) {
    return request.get("/tl-team-info/getTollmanTeamInfoList", {
      pageNum,
      pageSize
    });
  },

  /**
   * 获取收费员考勤信息数据
   * @param {Long} employeeId 员工id
   * @param {Date} time 指定年月
   */
  getTollmanAttendanceInfo({ employeeId, time }) {
    return request.get("/tl-team-info/getTollmanAttendanceInfo", {
      employeeId,
      time
    });
  },

  /**
   * 重置密码
   * @param {Long} employeeId 员工id
   */
  resetPassword({ employeeId }) {
    return request.get("/tl-team-info/resetPassword", { employeeId });
  },

  //------------------排班管理----------------
  /**
   * 根据岗位id获取排班模板数据
   * @param {Long} stationId 岗位id
   * @param {Date} time 日期
   */
  getTeamTollShiftTemplates({ stationId, time }) {
    return request.get("/tl-schedule-management/getTollShiftTemplates", {
      stationId,
      time
    });
  },

  /**
   * 获取排班管理信息数据
   * @param {Date} time 日期
   * @param {Long} stationId 岗位id
   * @param {Long} shiftTemplateId 排班模板id
   */
  editScheduleManagement({ time, stationId, shiftTemplateId }) {
    return request.get("/tl-schedule-management/editScheduleManagement", {
      time,
      stationId,
      shiftTemplateId
    });
  },

  /**
   * 新增排班管理信息数据
   * @param {Long} tollStationId 岗位id
   * @param {Long} tollEmployeeId 员工id
   * @param {Date} shiftDate 日期
   * @param {Long} shiftTemplateId 模板id
   * @param {Long} shiftId 班次id
   */
  addScheduleManagement({
    tollStationId,
    tollEmployeeId,
    shiftDate,
    shiftTemplateId,
    shiftId
  }) {
    return request.post("/tl-schedule-management/addScheduleManagement", {
      tollStationId,
      tollEmployeeId,
      shiftDate,
      shiftTemplateId,
      shiftId
    });
  },

  /**
   * 删除排班管理信息数据
   * @param {Long} tollStationId 岗位id
   * @param {Long} tollEmployeeId 员工id
   * @param {Date} shiftDate 日期
   * @param {Long} shiftId 班次id
   */
  deleteScheduleManagement({
    tollStationId,
    tollEmployeeId,
    shiftDate,
    shiftId
  }) {
    return request.post("/tl-schedule-management/deleteScheduleManagement", {
      tollStationId,
      tollEmployeeId,
      shiftDate,
      shiftId
    });
  },

  //------------------消息通知模块----------------
  /**
   * 分页获取消息通知列表
   * @param {Integer} pageNum 页数
   * @param {Integer} pageSize 条数
   */
  getMessageList({ pageNum, pageSize }) {
    return request.get("/tl-message/getMessageList", { pageNum, pageSize });
  },

  /**
   * 消息设置为已读
   * @param {String} captainMessageId 消息Id
   */
  updateCaptainMessage(captainMessageId) {
    return request.put(`/tl-message/updateCaptainMessage/${captainMessageId}`, {
      captainMessageId
    });
  },

  /**
   * 获取车辆曾经的订单信息
   * @param {String} plateNumber
   * @param {Integer} pageNum
   * @param {Integer} pageSize
   * @param {Integer} type
   */
  getParkingRecordByNumber({ plateNumber, pageNum, pageSize, type }) {
    return request.get("/tl-message/getParkingRecordByNumber", {
      plateNumber,
      pageNum,
      pageSize,
      type
    });
  },

  /**
   * 未读消息总数
   */
  getMessageCount() {
    return request.get("/tl-message/getMessageCount");
  },

  /**
   * 获取apk最新版本信息(队长端)
   */
  updateCaptainVersion() {
    return request.get("/apk-manage/updateCaptainVersion");
  },

  //-------------------------场内收费情况模块---------------------------------
  /**
   * 获取总览今日收费数据
   * @param {String} plateNumber
   */
  insideGetDayCharge(params) {
    return request.get("/tl-toll-status-garage/getDayCharge", params);
  },

  insideGetParkingGarageByCaptain() {
    return request.get("/tl-toll-status-garage/getParkingGarageByCaptain");
  },

  insideGetIncome(params) {
    return request.get("/tl-toll-status-garage/getIncome", params);
  },

  insideGetParkingGarageOrderFlow({ timeFrom, timeTo, dateType, type }) {
    return request.get("/tl-toll-status-garage/getParkingGarageOrderFlow", {
      timeFrom,
      timeTo,
      dateType,
      type
    });
  },

  // --------------------优 惠 券 业 务 板 块----------------------------
  getCouponsList(pageNum = 1, pageSize = 10, parkingGarageId = null) {
    return request.get("/tl-coupon-in", {
      pageSize,
      pageNum,
      parkingGarageId
    });
  },

  getCouponsToken(couponId) {
    return request.get("/tl-coupon-in/getUrlAndToken", {
      id: couponId
    });
  },

  getCouponInPlate(couponId, pageNum = 1, pageSize = 10) {
    return request.get("/tl-coupon-in/getCouponInPlate", {
      id: couponId,
      pageNum,
      pageSize
    });
  },

  refreshQrCodeStatus(token) {
    return request.get("/tl-coupon-in/refreshQrCodeStatus", {
      token
    });
  }
};

export default api;
