export default {
  /**
   * 扇形图表初始化选项函数
   * @param {Number} orderAmount 总收入
   * @param {Number} onlinePayAmount  线上支付
   * @param {Number} offlinePayAmount 线下支付
   * @param {Number} refundAmount 退款金额
   */
  createfanChartOptions(
    {
      orderAmount,
      onlinePayAmount,
      offlinePayAmount
      // refundAmount
    },
    fontSize = 20
  ) {
    return {
      tooltip: {
        trigger: "item",
        formatter: "{b}:{d}%"
      },
      // graphic: { //图形中间文字
      //     type: "text",
      //     left: "center",
      //     top: "center",
      //     style: {
      //         text: `${orderAmount}\n总收入(元)`,
      //         textAlign: "center",
      //         fill: "#333",
      //         fontSize: 16
      //     }
      // },
      grid: {
        height: "100%"
      },
      color: ["#90ec7d", "#f7a35b", "#8085e9"],
      series: [
        {
          name: "今日收费",
          type: "pie",
          center: ["50%", "50%"],
          radius: ["70%", "90%"],
          avoidLabelOverlap: false,
          // label: {
          //     show: false,
          //     position: 'center'
          // },
          label: {
            normal: {
              show: true,
              position: "center",
              color: "#4c4a4a",
              formatter:
                "{total|" + orderAmount + "}" + "\n\r" + "{active|总收入(元)}",
              rich: {
                total: {
                  fontSize,
                  fontFamily: "微软雅黑",
                  color: "#454c5c"
                  // lineHeight:30,
                },
                active: {
                  fontFamily: "微软雅黑",
                  fontSize: fontSize - 6,
                  color: "#6c7a89",
                  lineHeight: 30
                }
              }
            },
            emphasis: {
              //中间文字显示
              show: true
            }
          },
          // emphasis: {
          //     label: {
          //         show: true,
          //         fontSize: '30',
          //         fontWeight: 'bold'
          //     }
          // },
          labelLine: {
            show: false
          },
          data: [
            {
              value: onlinePayAmount,
              name: "线上支付"
            },
            {
              value: offlinePayAmount,
              name: "线下支付"
            }
            // {
            //     value: refundAmount,
            //     name: '退款金额'
            // },
          ]
        }
      ]
    };
  },

  /**
   * 线性图表选项初始化函数
   * @param {Object} data
   */
  createLineChartOptions(data) {
    let options = {
      tooltip: {
        trigger: "item",
        formatter: "{b}<br/>{a}：{c}",
        borderColor: ["#7cb5ec", "#434348", "#90ed7d"],
        backgroundColor: "rgba(255,255,255,0.8)",
        textStyle: {
          color: "#333",
          fontSize: 12
        },
        hideDelay: 500,
        borderWidth: 1,
        extraCssText: "box-shadow: 0 0 3px rgba(0, 0, 0, 0.3);"
      },

      legend: {
        data: ["应收金额", "实收金额", "欠费金额"]
      },
      grid: {
        left: "3%",
        right: "4%",
        bottom: "3%",
        top: "15%",
        containLabel: true
      },
      xAxis: {
        type: "category",
        boundaryGap: true,
        axisTick: {
          alignWithLabel: true,
          show: false
        },
        data: data.days
      },
      yAxis: {
        type: "value",
        splitNumber: 3,
        axisLine: {
          show: false
        },
        axisLabel: {
          formatter: v => {
            let newV = v;
            if (v >= 1000) {
              newV = Math.floor(v / 1000);
              return newV + "K";
            }
            return newV;
          }
        }
      },
      series: [
        {
          symbol: "diamond",
          name: "应收金额",
          type: "line",

          color: "#434348",
          symbolSize: 8,
          itemStyle: {
            color: "" //折线点的颜色
          },
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          symbol: "circle",
          name: "实收金额",
          type: "line",

          symbolSize: 8,
          color: "#7cb5ec",
          itemStyle: {
            color: "" //折线点的颜色
          },
          data: [120, 132, 101, 134, 90, 230, 210]
        },
        {
          symbol: "rectangle",
          name: "欠费金额",
          type: "line",
          color: "#90ed7d",

          symbolSize: 8,
          itemStyle: {
            normal: {
              color: "" //折线点的颜色
            }
          },
          data: [120, 132, 101, 134, 90, 230, 210]
        }
      ]
    };

    let legendList = [];

    // 判断是否为场内图表
    // if (data["isInside"]) {
    //   options.series.splice(2, 1);
    //   console.log(options.series);
    // }

    options.series.forEach((item, i) => {
      item["data"] = data["dayData"][i]["data"];
      item["name"] = data["dayData"][i]["name"];

      legendList.push(data["dayData"][i]["name"]);
    });

    options.legend.data = legendList;

    return options;
  }
};
