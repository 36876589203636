import store from '@/store';
import chartTools from './chartTools' ;

export default {
    ...chartTools,

    //显示加载
    showLoading() {
        store.commit('showLoading');
    },

    //关闭加载
    closeLoading() {
        setTimeout(() => {
            store.commit('closeLoading');
            
        }, 500)
    },

    //进入页面
    entering() {
        this.showLoading();
        setTimeout(() => {
            this.closeLoading();
        }, 500)
    },
}