import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

//引入axios
import axios from "axios";
import VueAxios from "vue-axios";
Vue.use(VueAxios, axios);

import VueAwesomeSwiper from "vue-awesome-swiper";

// import style
import "swiper/swiper.min.css";

Vue.use(VueAwesomeSwiper /* { default options with global component } */);

//引入rem
import "amfe-flexible";

//引入md5
import md5 from "js-md5";

//引入vant
import Vant from "vant";
import "vant/lib/index.css";
Vue.use(Vant);

import "./assets/css/style.css";

import filters from "./utils/filters";
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key]);
});

import echarts from "echarts";
import * as config from "./config/config";
import request from "@/utils/request";
import $tools from "./utils/tools";
import $jsBridge from "@/utils/JsBridgeTools.js";
import Native from "./utils/NativeUtil";
import db from "./utils/localstorage";
import testRequest from "./utils/testRequest";
import api from "./api/api";

$jsBridge.appVerName(res => {
  if (res) {
    store.commit("setIsApp", true);
  }
});

Vue.prototype.$db = db;
Vue.prototype.$api = api;
Vue.prototype.$echarts = echarts;
Vue.prototype.$config = config;
Vue.prototype.$post = request.post;
Vue.prototype.$get = request.get;
Vue.prototype.$put = request.put;
Vue.prototype.$delete = request.delete;
Vue.prototype.$tools = $tools;
Vue.prototype.$md5 = md5;
Vue.prototype.$jsBridge = $jsBridge;
Vue.prototype.$native = Native;
Vue.prototype.$test = testRequest.test;

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
