export const overviewData = {
  onlinePayAmount: 0,
  offlinePayAmount: 0,
  refundAmount: 0,
  parkingSpaceQty: 0,
  orderAmount: 0
};
export const lineChartData = {
  dayData: [
    {
      name: "应收金额",
      data: [0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: "实收金额",
      data: [0, 0, 0, 0, 0, 0, 0]
    },
    {
      name: "欠费金额",
      data: [0, 0, 0, 0, 0, 0, 0]
    }
  ],
  days: ["10-27", "10-28", "10-29", "10-30", "10-31", "11-01", "11-02"]
};

export const requestBaseUrl = "http://172.18.10.75:9021";
export const msgMode = '{"notify":"1","voice":"1"}';

export const LOGIN = "/tl-login/login";
export const TL_CHARGE_STATUS_GET_DAY_CHARGE = "/tl-charge-status/getDayCharge";
export const TL_CHARGE_STATUS_GET_STATION_ORDER_FLOW =
  "/tl-charge-status/getStationOrderFlow";

export const TL_CHARGE_STATUS_GET_INCOME = "/tl-charge-status/getIncome";
export const TL_CHARGE_STATUS_GET_STATION_BY_CAPTAIN =
  "/tl-charge-status/getStationByCaptain"; //获取收费岗信息

export const TL_CHARGE_STATUS_GET_RANKING_LIST =
  "/tl-charge-status/getRankingList";

export const EMPLOYEE_UPDATE_PASSWORD = "/employee/tlUpdatePassword"; //修改密码
export const TL_CHARGE_STATUS_GET_TODAY_FOCUS =
  "/tl-charge-status/getTodayFocus"; //获取今日关注数据

export const TL_CHARGE_STATUS_GET_TODAY_FOCUS_LIST =
  "/tl-charge-status/getTodayFocusList"; //获取今日关注列表数据

/**
 * 班组信息模块
 */
export const TL_TEAM_INFO_GET_TEAM_INFO_LIST = "/tl-team-info/getTeamInfoList"; //分页获取收费岗班组信息列表
export const TL_TEAM_INFO_GET_ATTENDANCE_INFO =
  "/tl-team-info/getAttendanceInfo"; //获取收费岗考勤信息数据

export const TL_TEAM_INFO_GET_TOLL_MAN_TEAM_INFO_LIST =
  "/tl-team-info/getTollmanTeamInfoList"; //分页获取收费员班组信息列表

export const TL_TEAM_INFO_GET_TOLL_MAN_ATTENDANCE_INFO =
  "/tl-team-info/getTollmanAttendanceInfo"; //获取收费员考勤信息数据

export const TL_TEAM_INFO_RESET_PASSWORD = "/tl-team-info/resetPassword"; //重置密码

/**
 * 排班管理
 */
export const TL_SCHEDULE_MANAGEMENT_GET_TOLL_SHIFT_TEMPLATES =
  "/tl-schedule-management/getTollShiftTemplates"; //根据岗位id获取排班模板数据

export const TL_SCHEDULE_MANAGEMENT_EDIT_SCHEDULE_MANEGEMENT =
  "/tl-schedule-management/editScheduleManagement"; //获取排班管理信息数据

export const TL_SCHEDULE_MANAGEMENT_ADD_SCHEDULE_MANAGEMENT =
  "/tl-schedule-management/addScheduleManagement"; //新增排班管理信息数据

export const TL_SCHEDULE_MANAGEMENT_DELETE_SCHEDULE_MANAGEMENT =
  "/tl-schedule-management/deleteScheduleManagement"; //删除排班管理信息数据

/**
 * 消息通知模块
 */
export const TL_MESSAGE_GET_MESSAGE_LIST = "/tl-message/getMessageList"; //分页获取消息通知列表
export const TL_MESSAGE_UPDATE_CAPTION_MESSAGE =
  "/tl-message/updateCaptainMessage/"; //消息设置为已读

export const TL_MESSAGE_GET_PARKING_RECORD_BY_NUMBER =
  "/tl-message/getParkingRecordByNumber"; //获取车辆曾经的订单信息

export const TL_MESSAGE_GET_MESSAGE_COUNT = "/tl-message/getMessageCount"; //未读消息总数
export const APK_MANAGE_UPDATE_CAPTAIN_VERSION =
  "/apk-manage/updateCaptainVersion"; //获取apk最新版本信息(队长端)
